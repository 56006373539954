<template>
    <div class="card card-default">
        <div class="card-header bg-gray-lighter text-bold">{{ $t('common.view.settings.menu.market') }}</div>
        <div class="card-body">

            <b-list-group class="mb-3">
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" @change="updateSettings()" v-model="growing.market_enable_cryptocurrency"/>
                        <span></span>
                    </label>

                    <span>{{ $t('growing.view.settings.market.switch.enable_crypto.label') }}</span>
                </b-list-group-item>
            </b-list-group>

            <crypto-address-form entity-slot="market" currency="BTC" v-if="growing.market_enable_cryptocurrency"></crypto-address-form>

            <hr>

            <b-list-group class="mb-3">
                <b-list-group-item>

                    <label class="switch d-inline mr-2">
                        <input type="checkbox" checked="checked" @change="updateSettings()" :disabled="$store.getters.user('verification_level') !== 2" v-model="growing.market_enable_bank_transfer"/>
                        <span></span>
                    </label>

                    <span>{{ $t('growing.view.settings.market.switch.enable_bank.label') }}</span>
                    <span v-if="$store.getters.user('verification_level') !== 2" class="text-warning"><br/>{{ $t('growing.view.settings.market.switch.enable_bank.hint') }}</span>
                </b-list-group-item>
            </b-list-group>

            <bank-account-form entity-slot="market" v-if="growing.market_enable_bank_transfer"></bank-account-form>
        </div>
    </div>
</template>

<script>
    import CryptoAddressForm from "../../../../modules/Wallet/components/Forms/CryptoAddressForm.vue";
    import BankAccountForm from "../../../../modules/Wallet/components/Forms/BankAccountForm.vue";

    export default {
        components: {
            CryptoAddressForm,
            BankAccountForm
        },
        data() {
            return {
                growing: {
                    market_enable_cryptocurrency: false,
                    market_enable_bank_transfer: false
                }
            };
        },
        created() {
            this.loadSettings();
        },
        methods: {
            loadSettings() {
                this.$api.get('growing/market/settings').then(response => {
                    this.growing = response.data.data;
                })
            },
            updateSettings() {
                this.$api.update('growing/market/settings', this.growing).catch(response => {
                    this.$swal.fire('Validation error', response.data.message, 'error');
                    this.loadSettings();
                });
            }
        }
    }
</script>