<template>
    <form @submit.prevent="submit()" ref="form" :class="loading && !hideConfirmButton ? 'whirl' : ''">

        <b-form-group>
            <label>{{$t('wallet.view.action.make_withdraw.method.bitcoin.address')}}</label>
            <b-input type="text" class="text-center" v-model="model.address" required=""></b-input>
        </b-form-group>

        <div class="text-right">
            <button class="btn btn-info" v-show="!hideConfirmButton" type="submit" :disabled="loading">{{ $t('common.button.save.label') }}</button>
        </div>

    </form>
</template>
<script>
    import SlotEntityForm from "../../../Common/components/SlotEntityForm";

    export default {
        extends: SlotEntityForm,
        data() {
            return {
                model: {
                    address: '',
                    currency: 'BTC'
                },
                apiEndpoint: 'wallet/withdraw/account/crypto'
            }
        }
    }
</script>
